import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
import UpgradeGuide from '@workday/canvas-kit-docs/dist/mdx/11.0-UPGRADE-GUIDE.mdx';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TabPanel = makeShortcode("TabPanel");
const ExternalContent = makeShortcode("ExternalContent");
const InternalContent = makeShortcode("InternalContent");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <TabPanel data-id="Overview" mdxType="TabPanel">
      <ExternalContent mdxType="ExternalContent">
        <h3 {...{
          "id": "upgrade-overview"
        }}>{`Upgrade Overview`}</h3>
        <p>{`The table below contains a high-level overview of the updates that will be rolled out as part of the
v11 release. The impact for developers are defined as follows:`}</p>
        <ul>
          <li parentName="ul"><strong parentName="li">{`None`}</strong>{`: inapplicable to the role or no actions are required for users to adopt the change;
Updates will be applied automatically once users upgrade to Canvas Kit v11`}</li>
          <li parentName="ul"><strong parentName="li">{`Low`}</strong>{`: minor changes are required for users to adopt the change`}</li>
          <li parentName="ul"><strong parentName="li">{`Medium`}</strong>{`: a moderate amount of changes are required for users to adopt the change, such as
switching out UI elements`}</li>
          <li parentName="ul"><strong parentName="li">{`High`}</strong>{`: a large amount of changes are required for users to adopt the change, requiring product
teams to make major design or development decisions`}</li>
        </ul>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Change`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Short Description`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Developer Impact`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`New Color System Tokens`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A new set of semantic color system tokens will be released.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Updated Depth and Opacity Tokens`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Depth and opacity tokens will be updated to use the new color system tokens.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`New Breakpoint Tokens`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`New breakpoint tokens will be provided to help teams apply responsive styles.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`The Return of Canvas Kit CSS`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Canvas Kit CSS is making a comeback! As components are restyled in Canvas Kit React, they will be made available in Canvas Kit CSS.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`High`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Table (Preview) Refactor & Promotion`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The Table component in Preview will be refactored to use the new `}<inlineCode parentName="td">{`cs`}</inlineCode>{` prop and CSS variables and promoted, replacing the Table in the Main package.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`High`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Icon Components Refactor`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The Svg, System Icon, System Icon Circle, Accent Icon, Applet Icon, and Graphic icon components will all be refactored to use the new `}<inlineCode parentName="td">{`cs`}</inlineCode>{` prop and CSS variables.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Medium`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Form Field (Preview) Refactor`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Form Field (Preview) will be refactored to use the new `}<inlineCode parentName="td">{`cs`}</inlineCode>{` prop and CSS variables, making it available for use with the current input components in Main.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Low`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Text Components Refactor`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Text components will be refactored to use the new `}<inlineCode parentName="td">{`cs`}</inlineCode>{` prop and CSS variables.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Low`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Status Indicator (Preview) Refactor & Model Removal`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The Status Indicator component in Preview will be refactored to use the new `}<inlineCode parentName="td">{`cs`}</inlineCode>{` prop and CSS variables with some minor visual changes. Models used in Status Indicator will also be removed.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Low`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Button Token Updates`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Canvas Primary, Secondary, Tertiary, and Delete buttons will be updated to use the new color system tokens. Fixes will also be applied that will result in minor visual changes.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Low`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Checkbox Refactor`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Checkbox will be refactored to use the new `}<inlineCode parentName="td">{`cs`}</inlineCode>{` prop and CSS variables with some minor visual changes.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Low`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Radio (Preview) Refactor`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The Radio component in Preview will be refactored to use the new `}<inlineCode parentName="td">{`cs`}</inlineCode>{` prop and CSS variables with some minor visual changes.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Low`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Switch Refactor`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Switch will be refactored to use the new `}<inlineCode parentName="td">{`cs`}</inlineCode>{` prop and CSS variables with some minor visual changes.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Low`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Card Refactor`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Card will be refactored to use the new `}<inlineCode parentName="td">{`cs`}</inlineCode>{` prop and CSS variables.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Select Refactor`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Select will be refactored to use the new `}<inlineCode parentName="td">{`cs`}</inlineCode>{` prop and CSS variables.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Count Badge Refactor`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Count Badge  will be refactored to use the new `}<inlineCode parentName="td">{`cs`}</inlineCode>{` prop and CSS variables.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Component Deprecations`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Text Input (Preview), Text Area (Preview), Form Field (Main), and Label Text (Main) will all be deprecated in v11.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
            </tr>
          </tbody>
        </table>
      </ExternalContent>
      <InternalContent mdxType="InternalContent">
        <h3 {...{
          "id": "upgrade-overview-1"
        }}>{`Upgrade Overview`}</h3>
        <p>{`The table below contains a high-level overview of the updates that will be rolled out as part of the
v11 release. The impact for designers and developers are defined as follows:`}</p>
        <ul>
          <li parentName="ul"><strong parentName="li">{`None`}</strong>{`: inapplicable to the role or no actions are required for users to adopt the change;
Updates will be applied automatically once users upgrade to Canvas Kit v11 and/or switch to the new
v11 Canvas Web Figma library`}</li>
          <li parentName="ul"><strong parentName="li">{`Low`}</strong>{`: minor changes are required for users to adopt the change`}</li>
          <li parentName="ul"><strong parentName="li">{`Medium`}</strong>{`: a moderate amount of changes are required for users to adopt the change, such as
switching out UI elements`}</li>
          <li parentName="ul"><strong parentName="li">{`High`}</strong>{`: a large amount of changes are required for users to adopt the change, requiring product
teams to make major design or development decisions`}</li>
        </ul>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Change`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Short Description`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Designer Impact`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Developer Impact`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`New Color System Tokens`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A new set of semantic color system tokens will be released.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Updated Depth and Opacity Tokens`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Depth and opacity tokens will be updated to use the new color system tokens.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`New Breakpoint Tokens`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`New breakpoint tokens will be provided to help teams apply responsive styles.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`The Return of Canvas Kit CSS`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Canvas Kit CSS is making a comeback! As components are restyled in Canvas Kit React, they will be made available in Canvas Kit CSS.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`High`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Table (Preview) Refactor & Promotion`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The Table component in Preview will be refactored to use the new `}<inlineCode parentName="td">{`cs`}</inlineCode>{` prop and CSS variables and promoted, replacing the Table in the Main package.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`High`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Icon Components Refactor`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The Svg, System Icon, System Icon Circle, Accent Icon, Applet Icon, and Graphic icon components will all be refactored to use the new `}<inlineCode parentName="td">{`cs`}</inlineCode>{` prop and CSS variables.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Medium`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Form Field (Preview) Refactor`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Form Field (Preview) will be refactored to use the new `}<inlineCode parentName="td">{`cs`}</inlineCode>{` prop and CSS variables, making it available for use with the current input components in Main.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Low`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Text Components Refactor`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Text components will be refactored to use the new `}<inlineCode parentName="td">{`cs`}</inlineCode>{` prop and CSS variables.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Low`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Status Indicator (Preview) Refactor & Model Removal`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The Status Indicator component in Preview will be refactored to use the new `}<inlineCode parentName="td">{`cs`}</inlineCode>{` prop and CSS variables with some minor visual changes. Models used in Status Indicator will also be removed.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Low`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Button Token Updates`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Canvas Primary, Secondary, Tertiary, and Delete buttons will be updated to use the new color system tokens. Fixes will also be applied that will result in minor visual changes.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Low`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Checkbox Refactor`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Checkbox will be refactored to use the new `}<inlineCode parentName="td">{`cs`}</inlineCode>{` prop and CSS variables with some minor visual changes.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Low`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Radio (Preview) Refactor`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The Radio component in Preview will be refactored to use the new `}<inlineCode parentName="td">{`cs`}</inlineCode>{` prop and CSS variables with some minor visual changes.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Low`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Switch Refactor`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Switch will be refactored to use the new `}<inlineCode parentName="td">{`cs`}</inlineCode>{` prop and CSS variables with some minor visual changes.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Low`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Card Refactor`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Card will be refactored to use the new `}<inlineCode parentName="td">{`cs`}</inlineCode>{` prop and CSS variables.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Select Refactor`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Select will be refactored to use the new `}<inlineCode parentName="td">{`cs`}</inlineCode>{` prop and CSS variables.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Count Badge Refactor`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Count Badge  will be refactored to use the new `}<inlineCode parentName="td">{`cs`}</inlineCode>{` prop and CSS variables.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Component Deprecations`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Text Input (Preview), Text Area (Preview), Form Field (Main), and Label Text (Main) will all be deprecated in v11.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
            </tr>
          </tbody>
        </table>
      </InternalContent>
    </TabPanel>
    <TabPanel data-id="For Developers" mdxType="TabPanel">
      <UpgradeGuide mdxType="UpgradeGuide" />
    </TabPanel>
    <TabPanel data-id="For Designers" mdxType="TabPanel">
      <h2 {...{
        "id": "getting-started"
      }}>{`Getting Started`}</h2>
      <p>{`This upgrade guide contains an overview of changes designers can expect in Canvas v11 as well as step-by-step instructions for upgrading to the latest version of the Canvas Figma libraries (Canvas Web v11 and Canvas Tokens v2).`}</p>
      <h3 {...{
        "id": "deciding-when-to-upgrade"
      }}>{`Deciding When to Upgrade`}</h3>
      <p>{`With the release of the versioned Canvas Web Figma libraries, designers now have the ability to be on the same version of Canvas as their developers. Designers should work with their developer to align on the Canvas version that should be used in design and code. Using the same Canvas version across design and code helps promote a smooth design to development handoff process by ensuring that:`}</p>
      <ul>
        <li parentName="ul">{`The same components are available across Figma and code`}</li>
        <li parentName="ul">{`Component styles are consistent across Figma and code`}</li>
      </ul>
      <p>{`We recommend chatting with your wider team to understand what version they are currently building with, and when they plan to upgrade to the next version of Canvas. For some teams this could take a few days, for others it could be weeks. Setting an upgrade date will keep the team on the same page.`}</p>
      <h3 {...{
        "id": "preparing-for-the-upgrade"
      }}>{`Preparing for the Upgrade`}</h3>
      <p>{`There are things that can be done before upgrading to v11 that will streamline the process and save time. Updating the covers of your files will help identify what files are planned to upgrade to v11. This has the added benefit of helping your team differentiate what you plan to maintain going forward and what you might archive within your team's workspaces to improve findability.`}</p>
      <h4 {...{
        "id": "identify-key-files-for-the-upgrade"
      }}>{`Identify Key Files for the Upgrade`}</h4>
      <p>{`Mark the covers of the files that should be upgraded to Canvas Web v11. This helps your team identify which files are actively being maintained and which should be archived. To reduce the time spent addressing breaking changes it’s recommended that the duplicate or saved version of your files are consolidated to just the key screens that are frequently used to design with. These key screens can then be updated to handle breaking changes allowing you to continue to flesh out any future designs whilst cutting back on clutter. All previous designs can be archived should you need to reference these in the future.`}</p>
      <h4 {...{
        "id": "back-up-key-files"
      }}>{`Back Up Key Files`}</h4>
      <p>{`Before upgrading, consider backing up your current version through a branch.`}</p>
      <h4 {...{
        "id": "update-the-current-version"
      }}>{`Update the Current Version`}</h4>
      <p>{`Before upgrading to Canvas Web v11, make sure to accept any changes in Canvas Web v10. While there shouldn't be any breaking changes in v11, this is a precautionary action to prevent unforeseen changes.`}</p>
      <h4 {...{
        "id": "create-a-branch-of-the-main-file-recommended"
      }}>{`Create a Branch of the Main File (Recommended)`}</h4>
      <p><a parentName="p" {...{
          "href": "https://help.figma.com/hc/en-us/articles/360063144053-Guide-to-branching"
        }}>{`Branching`}</a>{` saves a version of your current file and creates a separate space to upgrade. Merging back to main will show the before and after, as well as any diffs.`}</p>
      <h4 {...{
        "id": "duplicate-key-files"
      }}>{`Duplicate Key Files`}</h4>
      <p>{`Alternatively, you can duplicate essential files and upgrade those, indicating the Canvas version for clarity.`}</p>
      <h3 {...{
        "id": "making-the-upgrade"
      }}>{`Making the Upgrade`}</h3>
      <p>{`Before starting, check which version of `}{`[Canvas Tokens]`}{` your file is using.`}</p>
      <p>{`For teams on `}<a parentName="p" {...{
          "href": "https://www.figma.com/design/FyRooH18FV7mvkTQqRgYMAz8/Canvas-Tokens-v1?m=auto"
        }}>{`the Canvas Tokens v1 Figma library`}</a>{`, `}<a parentName="p" {...{
          "href": "/help/upgrade-guides/canvas-v11-upgrade-guide#tab=for-designers&heading=upgrade-to-canvas-tokens-v2"
        }}>{`start here`}</a>{`.`}</p>
      <p>{`For  teams on `}<a parentName="p" {...{
          "href": "https://www.figma.com/design/GmIqhQ7KqX6WQZFimdn756/Canvas-Tokens-v2?m=auto"
        }}>{`the Canvas Tokens v2 Figma library`}</a>{`, `}<a parentName="p" {...{
          "href": "/help/upgrade-guides/canvas-v11-upgrade-guide#tab=for-designers&heading=upgrade-to-canvas-web-v11"
        }}>{`start here`}</a>{`.`}</p>
      <h4 {...{
        "id": "upgrade-to-canvas-tokens-v2"
      }}>{`Upgrade to Canvas Tokens v2`}</h4>
      <p>{`First, make sure that you are on the most recent version of Canvas Tokens.`}</p>
      <p>{`Steps:`}</p>
      <ol>
        <li parentName="ol">{`Go to your design file`}</li>
        <li parentName="ol">{`Create a Figma branch labeled 'Upgrade to v11'`}</li>
        <li parentName="ol">{`Make sure you are in the branch you just created`}</li>
        <li parentName="ol">{`Go to active libraries using the `}<strong parentName="li">{`assets panel`}</strong>{` or use `}<inlineCode parentName="li">{`cmd`}</inlineCode>{` + `}<inlineCode parentName="li">{`shift`}</inlineCode>{` + `}<inlineCode parentName="li">{`p`}</inlineCode>{` to search for 'Libraries\``}</li>
        <li parentName="ol">{`Locate and click into the Canvas Tokens v1 Figma library`}</li>
        <li parentName="ol">{`Library Swap to the Canvas Tokens v2 Figma library`}</li>
        <li parentName="ol">{`Merge the branch back into the main file`}</li>
        <li parentName="ol">{`Check any visual differences in the modal`}</li>
      </ol>
      <p>{`If Styles did not swap contact #ask-canvas-design for help. Otherwise, proceed to the next section.`}</p>
      <h4 {...{
        "id": "upgrade-to-canvas-web-v11"
      }}>{`Upgrade to Canvas Web v11`}</h4>
      <p>{`Make sure that the previous section is complete before proceeding.`}</p>
      <p>{`Steps:`}</p>
      <ol>
        <li parentName="ol">{`Go to your design file`}</li>
        <li parentName="ol">{`Accept the changes from the Canvas Tokens v2 Figma library`}</li>
        <li parentName="ol">{`Create a Figma branch labeled 'Upgrade to v11'`}</li>
        <li parentName="ol">{`Make sure you are in the branch you just created`}</li>
        <li parentName="ol">{`Go to the file's active libraries (located in the assets panel)`}</li>
        <li parentName="ol">{`Locate and click into the Canvas Web v10 Figma library`}</li>
        <li parentName="ol">{`Library Swap to the Canvas Web v11 Figma library`}</li>
        <li parentName="ol">{`Merge the branch back into the main file`}</li>
        <li parentName="ol">{`Check for any visual differences`}</li>
      </ol>
      <h2 {...{
        "id": "changes-in-canvas-tokens-v2"
      }}>{`Changes in Canvas Tokens v2`}</h2>
      <p>{`New tokens and token updates are now available on Canvas Tokens v2. These changes will not impact existing designs, but designers will be given access to more semantic and scalable tokens. `}</p>
      <h3 {...{
        "id": "features"
      }}>{`Features`}</h3>
      <h4 {...{
        "id": "new-color-system-tokens"
      }}>{`New Color System Tokens`}</h4>
      <p>{`System level color tokens are now available in the Canvas Tokens v2 Figma library, which the Canvas Web v11 library is pulling from. These tokens are semantic, which will help teams understand how they should be applied in product. `}</p>
      <p>{`There will be three level of color system tokens available with this release:`}</p>
      <ul>
        <li parentName="ul"><strong parentName="li">{`Base:`}</strong>{` these color tokens are meant for one-off use cases, but should be avoided when possible as they will not always reflect the Workday brand. Examples of base color tokens include Watermelon 500 (--cnvs-base-palette-watermelon-500) and Plum 300 (--cnvs-base-palette-plum-300) `}</li>
        <li parentName="ul"><strong parentName="li">{`Brand:`}</strong>{` Brand tokens should only be used when tenant branding is possible / allowed. The color that brand tokens display will be dependent on the tenant configuration.`}</li>
        <li parentName="ul"><strong parentName="li">{`System:`}</strong>{` System tokens should be used in cases where tenant branding is not possible / allowed. This prevents tenant configuration from altering the color used in a component. System tokens are designed to make theming (non-tenant branding) easier in the future. `}</li>
      </ul>
      <p>{`Teams are advised to use System tokens over Base tokens whenever possible and to use Brand tokens with caution. `}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "719px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/8126eb4926ef37d1cf2acf9ae957b454/073e9/v11-color-system-tokens.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "62.432432432432435%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsTAAALEwEAmpwYAAACJElEQVR42pWSy2sTURjFB1yqCD6XoruZyUxnMpOZSGIyMQ+ykArVdCbdCYILXbmoD/rAP8GNIrgUXLlyUbuxgjsRhLoR1KIJthZsmkzbzCOZHu+9yZSCQemFj2+4c++Pc879uF4ELH31MbPQxvxiG3OLw/66TfY2sfDZQ9jfBV2e56Fer0OWZWQyGZimiUqlAkVRkEwmoes6uPfNAEfu/sDxBw2cnGnizFwTx8g37XTv0J3vePPFY8Cd7gDI8zwMw2AAURSRSCRYSZIE7vmHbXA3V1B5uo7pVy3cfrmB6y9+4xbpl5+tg7vxDY/fdRhwa2cApBDLspBOp/dAcXGrbh/FJ79w+F4Dp+ebOEWUnZgl6ojao/cbSD9aw8pGjwG71LLjkIsy8vk8sx4DaWeWIxJPx4vwaS3E8mqIh29dzC65+PgzwDLZa3UjRLuDDH3fh0OAiiyhVCxAT6UYjCqmMWSzWXD06PD8yEX/xUCPAKccG7xiolC9gnKpCE3T2IOoqspy5YIwhBeE6Pqjyw9oBej1+3C3tmHXJqBerKI47sDQVAaKgcxy1/Pxv6KvS8GbbRdO7RpEPYdseRyaOgZBEJllQRDY+BwI2Gp3iOVJnOV1pHJVWLkMA8U5UpUHVNiBbduQ5DFYhUtsdGiGFLYHHGQ0ujw/+Mvy1ORVnFMtGKUJXDA0CMPBpkBmGf9YYdhjsP0K63YN5yUTWrYMQ0+CJ5b3Z/gHkUGndDh7JtoAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Color system tokens displayed as Figma styles",
              "title": "Color system tokens displayed as Figma styles",
              "src": "/static/8126eb4926ef37d1cf2acf9ae957b454/073e9/v11-color-system-tokens.png",
              "srcSet": ["/static/8126eb4926ef37d1cf2acf9ae957b454/1efb2/v11-color-system-tokens.png 370w", "/static/8126eb4926ef37d1cf2acf9ae957b454/073e9/v11-color-system-tokens.png 719w"],
              "sizes": "(max-width: 719px) 100vw, 719px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p>{`This is a net-new feature that will not impact existing designs. To access color system tokens, designers will need to accept the changes made in the Canvas Tokens v2 Figma library in their design files.`}</p>
      <h3 {...{
        "id": "new-breakpoint-tokens"
      }}>{`New Breakpoint Tokens`}</h3>
      <p>{`New breakpoint tokens are now available through the Canvas Tokens v2 Figma library in the form of variables. They can be applied to auto-layout frames, allowing designers to set the min and max widths of their artboards with the new breakpoint variables. `}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "634px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/de6ef4a7f19e9f00f8405532532ad3a4/374ac/v11-breakpoint-tokens.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "50.27027027027027%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAABs0lEQVR42nXSy07CQBQGYJ+FEO7l1gIlAgGagM/lCqgRXbhx48aFL6EbExPdqIlujCaIbgwqgmLbaae/PVNAFG0ymWl6+s0/lxVMH8tiME0ThmFgMBhgNHoFY5Z4dxwHruuKOn1zC1JuFSVtDdG0gny+ALVQQDweRzKZxMoMdF0Ozl1YHsJsC2ODw2RcQJzzObixvYNQdhX5igZJrSIjK5CzWSQSCaTTaR+kYip3uY3Tewvn/TF6V3t4fOoJZBHUOx0Eg0GoxaIAKBlhkiQhFostgFTPGQ5vgf3LC+yerOPs4UYgDv9ecqvVFoCm1T0w9QOkNgfpsW0b44kJBo6hzfH8bvgJxYR+TcdLSEmq1SpKpRJkWUYmk0Eul/u55Bn45oH+mGH4D0ipms0mGo0GFEVB1ttDVVVF/yfocDqQv8F2u41AIIBKpYKit4+zJadSKdEvgaNpQtq34fvnEqjrukBqtZpIFwqFEIlEEI1Gfx+K33ovnzi6m+Cs/+GlZUtgt9sVAN05QmgcDodFo/HCPfR/GEwcHFxPcNw34S58o6szS0ipNE1DvV5HuVwWp0sT0MX+ApXIXNau42aMAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Breakpoint variables on Figma's right hand panel",
              "title": "Breakpoint variables on Figma's right hand panel",
              "src": "/static/de6ef4a7f19e9f00f8405532532ad3a4/374ac/v11-breakpoint-tokens.png",
              "srcSet": ["/static/de6ef4a7f19e9f00f8405532532ad3a4/1efb2/v11-breakpoint-tokens.png 370w", "/static/de6ef4a7f19e9f00f8405532532ad3a4/374ac/v11-breakpoint-tokens.png 634w"],
              "sizes": "(max-width: 634px) 100vw, 634px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p>{`This is a net-new feature that will not impact existing designs. To access breakpoint tokens, designers will need to accept the changes made in the Canvas Tokens v2 Figma library in their design files.`}</p>
      <h3 {...{
        "id": "enhancements"
      }}>{`Enhancements`}</h3>
      <h4 {...{
        "id": "updated-depth-and-opacity-tokens"
      }}>{`Updated Depth and Opacity Tokens`}</h4>
      <p>{`Depth and opacity tokens have been updated to use the new color system tokens. This does not impact the color displayed for depth and opacity tokens. Depth tokens are still available for use through Canvas Tokens v2 as a `}<a parentName="p" {...{
          "href": "https://help.figma.com/hc/en-us/articles/360039238753-Styles-in-Figma"
        }}>{`Figma style`}</a>{`. Unfortunately, opacity tokens are still not supported in Figma.`}</p>
      <h2 {...{
        "id": "changes-in-canvas-web-v11"
      }}>{`Changes in Canvas Web v11`}</h2>
      <p>{`Several components were refactored in Canvas v11 to use the new system tokens. While these system tokens will help ensure colors are applied consistently across Workday, they may result in a few minor design changes in Canvas components as they are being refactored to align to the new standard.`}</p>
      <h3 {...{
        "id": "features-1"
      }}>{`Features`}</h3>
      <h4 {...{
        "id": "new-grid"
      }}>{`New Grid`}</h4>
      <p>{`A new Grid style is now available, which utilizes the new breakpoint tokens. This Grid style will mimic the Grid component in code. Designers using the existing grid styles, should swap over to use the new Grid:`}</p>
      <ul>
        <li parentName="ul">{`Small (320px-767px) → Grid, Layer=Small`}</li>
        <li parentName="ul">{`Medium (768px-1023px) → Grid, Layer=Medium`}</li>
        <li parentName="ul">{`Large (1024px-1439px) → Grid, Layer=Large`}</li>
        <li parentName="ul">{`Extra-Large (≥1440px) → Grid, Layer=Large`}</li>
      </ul>
      <p>{`The old grid styles will be soft deprecated. This means that they will still be available for use in Canvas Web v11, but may be removed in future versions.`}</p>
      <h3 {...{
        "id": "enhancements-1"
      }}>{`Enhancements`}</h3>
      <h4 {...{
        "id": "new-refactored-components"
      }}>{`New Refactored Components`}</h4>
      <p>{`The following components refactored in v11 may have minor color updates  in the Canvas Web v11 Figma library:`}</p>
      <ul>
        <li parentName="ul">{`Status Indicator`}</li>
        <li parentName="ul">{`Buttons (Primary, Secondary, Tertiary, Delete)`}</li>
        <li parentName="ul">{`Checkbox`}</li>
        <li parentName="ul">{`Radio`}</li>
        <li parentName="ul">{`Switch`}</li>
      </ul>
      <p>{`These changes will be automatically applied once users perform a library swap over to the Canvas Web v11 Figma library.`}</p>
      <h2 {...{
        "id": "canvas-web-v8-library-deprecation"
      }}>{`Canvas Web v8 Library Deprecation`}</h2>
      <p>{`The Canvas Web v8 Figma library will be deprecated and moved to the Archive folder. Design files using this library will not be impacted.`}</p>
    </TabPanel>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      